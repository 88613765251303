import {
  customElement
} from "./chunk.7SN7OVTI.js";
import {
  s,
  x
} from "./chunk.55ES4XIJ.js";
import {
  i,
  r
} from "./chunk.5YGJIHTM.js";
import {
  __decorateClass
} from "./chunk.SO6RGV6L.js";

// src/components/icons/jb-clear-icon/jb-clear-icon.scss?inline
var jb_clear_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  cursor: pointer;
  display: inline-block;
  height: var(--icon-size, 25px);
  width: var(--icon-size, 25px);
}

svg {
  fill: rgb(var(--rgb-primary, 20, 30, 85));
}`;

// src/components/icons/jb-clear-icon/jb-clear-icon.ts
var JbClearIcon = class extends s {
  render() {
    return x`
      <svg
        viewBox="0 0 25 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M12.5 23C18.299 23 23 18.299 23 12.5C23 6.70101 18.299 2 12.5 2C6.70101 2 2 6.70101 2 12.5C2 18.299 6.70101 23 12.5 23ZM8.21504 16.785C7.92832 16.4982 7.92832 16.0334 8.21504 15.7467L11.4617 12.5L8.21504 9.25333C7.92832 8.96661 7.92832 8.50175 8.21504 8.21504C8.50175 7.92832 8.96661 7.92832 9.25333 8.21504L12.5 11.4617L15.7467 8.21504C16.0334 7.92832 16.4982 7.92832 16.785 8.21504C17.0717 8.50175 17.0717 8.96661 16.785 9.25333L13.5383 12.5L16.785 15.7467C17.0717 16.0334 17.0717 16.4982 16.785 16.785C16.4982 17.0717 16.0334 17.0717 15.7467 16.785L12.5 13.5383L9.25333 16.785C8.96661 17.0717 8.50175 17.0717 8.21504 16.785Z"
        />
      </svg>
    `;
  }
};
JbClearIcon.styles = r(jb_clear_icon_default);
JbClearIcon = __decorateClass([
  customElement("jb-clear-icon")
], JbClearIcon);

export {
  JbClearIcon
};
