import {
  JbUpload
} from "./chunk.HM3YWBKV.js";
import {
  JbTextField
} from "./chunk.IGNVNXWR.js";
import {
  JbTextArea
} from "./chunk.W66PM6RF.js";
import {
  JbRadio
} from "./chunk.2NISRHNJ.js";
import {
  JbMultiCheckbox
} from "./chunk.IRLTPCG7.js";
import {
  JbInfoBox
} from "./chunk.UEL5RIEF.js";
import {
  JbDatePicker
} from "./chunk.2ZJ4RFYZ.js";
import {
  JbSelect
} from "./chunk.3KOV4NE6.js";
import {
  JbRichtext
} from "./chunk.XD3UGKTT.js";
import {
  JbCheckbox
} from "./chunk.3R5M4Y3P.js";
import {
  JbButtonPrimary
} from "./chunk.6DWCBCVU.js";
import {
  Spaceable
} from "./chunk.5AVD7Z5R.js";
import {
  e as e2
} from "./chunk.5ICCMWV2.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  setLink
} from "./chunk.VB5GPMOP.js";
import {
  customElement
} from "./chunk.7SN7OVTI.js";
import {
  T,
  s,
  x
} from "./chunk.55ES4XIJ.js";
import {
  e,
  n
} from "./chunk.6SUS7CJL.js";
import {
  i,
  r
} from "./chunk.5YGJIHTM.js";
import {
  __decorateClass
} from "./chunk.SO6RGV6L.js";

// src/components/jb-form/jb-form.scss?inline
var jb_form_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
p.general-concern {
  font-family: var(--font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--font-bold-weight, 700);
}

p, h5, h3 {
  font-family: var(--font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--font-light-weight, 300);
}

p.general-concern {
  font-size: var(--typo-uppercase-700-fontsize, 11px);
  letter-spacing: var(--typo-uppercase-700-letterspacing, 1px);
  line-height: var(--typo-uppercase-700-lineheight, 16px);
  text-transform: uppercase;
}

h3 {
  font-size: var(--typo-h3-mobile-fontsize, 22px);
  line-height: var(--typo-h3-mobile-lineheight, 28px);
}
@media (min-width: 1281px) {
  h3 {
    font-size: var(--typo-h3-desktop-fontsize, 26px);
    line-height: var(--typo-h3-desktop-lineheight, 36px);
  }
}

h5 {
  font-size: var(--typo-p-big-mobile-fontsize, 19px);
  line-height: var(--typo-p-big-mobile-lineheight, 28px);
}
@media (min-width: 1281px) {
  h5 {
    font-size: var(--typo-p-big-desktop-fontsize, 22px);
    line-height: var(--typo-p-big-desktop-lineheight, 34px);
  }
}

p {
  font-size: var(--typo-p-small-desktop-fontsize, 16px);
  line-height: var(--typo-p-small-desktop-lineheight, 26px);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  --slotted-margin-block-start: calc(var(--spacing-small) - var(--spacing-xs));
  --textarea-title-margin-top: 38px;
  --textarea-title-margin-bottom: 20px;
}

input.internal {
  background: none;
  border: 0;
  display: block;
  height: 0;
  padding: 0;
}

label.internal {
  display: block;
  font-size: 0;
  height: 0;
  padding: 0;
}

button[type=submit].hidden {
  display: none;
}

.bordered {
  border: 1px solid rgba(20, 30, 85, var(--opacity-20, 0.2));
  padding: var(--spacing-lm);
  --button-secondary-margin: 20px 0 10px 0;
}
.bordered .grid {
  row-gap: var(--spacing-sm);
}

::slotted(jb-button-primary) {
  inline-size: 100%;
}
@media (min-width: 960px) {
  ::slotted(jb-button-primary) {
    inline-size: auto;
  }
}

p {
  text-align: center;
}
p.general-concern {
  color: rgba(var(--rgb-primary, 20, 30, 85), 0.8);
  margin-block-end: var(--spacing-xs);
}

h5 {
  margin-block-end: var(--spacing-2xs);
  text-align: center;
}

h3 {
  margin-block: var(--spacing-small) var(--spacing-2xs);
  --textarea-paragraph-margin-bottom: 0;
}

slot.grid {
  display: grid;
  gap: var(--spacing-xs) 40px;
  justify-items: start;
}
@media (min-width: 960px) {
  slot.grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
slot.flex {
  display: grid;
  gap: var(--form-flex-gap, var(--spacing-s));
  grid-template-columns: 1fr max-content;
}

jb-section-headline {
  padding-block-end: var(--spacing-lm);
}

::slotted(.combine) {
  display: inherit;
  gap: inherit;
  inline-size: 100%;
  justify-items: inherit;
  position: relative;
}
@media (min-width: 960px) {
  ::slotted(.combine) {
    grid-template-columns: repeat(2, 1fr);
  }
}

::slotted(jb-button-primary),
::slotted(jb-richtext),
::slotted(jb-checkbox) {
  margin-block-start: var(--slotted-margin-block-start);
}

::slotted(jb-richtext:first-child) {
  margin-block-start: var(--spacing-small);
}

@media (min-width: 960px) {
  ::slotted(:not(.width-half)) {
    grid-column: 1/3;
  }
}

::slotted(jb-richtext:last-of-type) {
  color: rgba(20, 30, 85, var(--opacity-70, 0.7));
}`;

// src/components/jb-form/jb-form.ts
var JbForm = class extends Initiable(Spaceable(s)) {
  constructor() {
    super(...arguments);
    this.bordered = false;
    this.displayGrid = true;
  }
  static create(formProperties) {
    return new JbForm().init({
      props: formProperties
    });
  }
  updated(_changedProperties) {
    if (!_changedProperties.has("contentElements"))
      return;
    this._generateForm();
  }
  async firstUpdated() {
    if (this.trackingSubject) {
      const componentObserver = new IntersectionObserver(
        async (entries, observer) => {
          if (entries[0].isIntersecting) {
            this.dispatchEvent(
              new CustomEvent("jb-tracking", {
                detail: {
                  event: "ce_form",
                  event_version: "v2",
                  component_name: "contact-form",
                  form_step_name: "form open",
                  form_step_nr: "1",
                  form_subject: this.trackingSubject,
                  form_type: "contact"
                },
                bubbles: true,
                composed: true
              })
            );
            observer.disconnect();
          }
        },
        {
          rootMargin: "0px"
        }
      );
      componentObserver.observe(this);
    }
    this._populateReferrerFields();
    this.addEventListener("field-update", (event) => {
      this._storeFieldsToLocalStorage(
        event.detail.field,
        event.detail.changedProperties
      );
    });
    await this.updateComplete;
    this._lastUpdated = Date.now();
    window.parent.postMessage(`formLoaded_${this.formIdentifier}`);
  }
  refreshValues() {
    this._slottedChildren.forEach((elm) => {
      const el = elm;
      if (el.populateValue && el.populateValue()) {
        el.populateValue();
      }
    });
  }
  validate() {
    let isValid = true;
    this._slottedChildren.forEach((child) => {
      if (child.validate) {
        isValid = child.validate() && isValid;
      }
    });
    return isValid;
  }
  _clearStoredData() {
    localStorage.removeItem("savedFormData");
  }
  // Method to generate the form components according to the contentElements property and add them to the slot
  _generateForm() {
    if (!this.contentElements || this.contentElements.length === 0)
      return;
    while (this.firstChild) {
      this.removeChild(this.firstChild);
    }
    let elm;
    this.contentElements.forEach((element) => {
      if (element.name === "formRow") {
        elm = document.createElement("div");
        elm.classList.add("combine");
        element.items?.forEach((singleElement) => {
          const formElm = this._makeFormElement(singleElement);
          if (!formElm)
            return;
          elm.appendChild(formElm);
        });
      } else {
        elm = this._makeFormElement(element);
      }
      if (!elm)
        return;
      this.appendChild(elm);
    });
  }
  _getContentSpacingProperties() {
    if (this.bordered) {
      return { grid: "legacy", colsMdUp: 8 };
    }
    return { grid: "legacy", colsMdUp: 8, colsLgUp: 6 };
  }
  _makeFormElement(componentProp) {
    const componentConstructor = {
      checkbox: JbCheckbox,
      datePicker: JbDatePicker,
      infoBox: JbInfoBox,
      multiCheckbox: JbMultiCheckbox,
      radio: JbRadio,
      richText: JbRichtext,
      select: JbSelect,
      submitButton: JbButtonPrimary,
      textArea: JbTextArea,
      textField: JbTextField,
      upload: JbUpload
    };
    if (!(componentProp.name in componentConstructor))
      return void 0;
    const ComponentClass = componentConstructor[componentProp.name];
    const componentInstance = new ComponentClass().init({
      props: componentProp.componentProperties,
      innerHTML: componentProp.innerHTML,
      childNodes: componentProp.childNodes,
      attributes: componentProp.attributes
    });
    return componentInstance;
  }
  _notifyCustomElements(e3) {
    this._slottedChildren.forEach((child) => {
      if (child.appendFormData) {
        child.appendFormData(e3);
      }
    });
  }
  _populateReferrerFields() {
    const params = new URLSearchParams(document.location.search.substring(1));
    if (this._referrerOriginalUrlField) {
      this._referrerOriginalUrlField.value = params.get("referrerOriginalUrl") || localStorage.getItem("page_url_english") || "";
    }
    if (this._referrerUrlField) {
      this._referrerUrlField.value = params.get("referrerUrl") || window.location.href;
    }
    if (this._referrerTitleField) {
      this._referrerTitleField.value = params.get("referrerTitle") || document.title;
    }
  }
  _renderHiddenFields() {
    if (this.additionalData === void 0 || Object.keys(this.additionalData).length === 0) {
      return T;
    }
    return Object.keys(this.additionalData).map(
      (key) => x`
        <input
          type="hidden"
          name="${key}"
          value="${this.additionalData ? this.additionalData[key] : ""}"
        />
      `
    );
  }
  _renderPhone(phoneString) {
    const phoneNumber = phoneString.match(/(?:[-+() ]*\d){10,13}/g);
    if (phoneNumber === null || phoneNumber[0] === null)
      return null;
    const anchor = document.createElement("a");
    const paragraph = document.createElement("p");
    anchor.innerText = phoneNumber[0].trim();
    setLink(anchor, { href: `tel:${phoneNumber[0].replace(/[^+1-9]/g, "")}` });
    paragraph.innerText = phoneString.replace(phoneNumber[0], "") + " ";
    paragraph.appendChild(anchor);
    return paragraph;
  }
  _renderValidationFields() {
    return x`
      <label
        class="internal"
        for="${this.validationFieldname}"
        >${this.validationFieldname}</label
      >
      <input
        type="text"
        name="${this.validationFieldname}"
        id="${this.validationFieldname}"
        class="internal"
        autocomplete="off"
        tabindex="-1"
        value=""
        maxlength="512"
      />
    `;
  }
  get _slottedChildren() {
    const returnArray = [];
    const slot = this.shadowRoot.querySelector("slot");
    slot.assignedElements({ flatten: true }).forEach((singleElement) => {
      if (singleElement.classList.contains("combine")) {
        singleElement.childNodes.forEach((child) => {
          returnArray.push(child);
        });
      } else {
        returnArray.push(singleElement);
      }
    });
    return returnArray;
  }
  _storeFieldsToLocalStorage(element, changedProperties) {
    if (changedProperties.has("value") && element.frontendRenderingIdentifier && typeof element.value === "string" && element.name.indexOf("checkbox") === -1) {
      let storedData = localStorage.getItem("savedFormData");
      if (storedData !== null) {
        storedData = JSON.parse(storedData);
      } else {
        storedData = {};
      }
      storedData[element.frontendRenderingIdentifier] = element.value;
      localStorage.setItem("savedFormData", JSON.stringify(storedData));
    }
  }
  _submit(e3) {
    const timeField = this.renderRoot.querySelector('input[name="form_analytics_time"]');
    const timeTaken = Date.now() - this._lastUpdated;
    if (timeField) {
      timeField.value = String(timeTaken);
    }
    if (e3.detail.name === "submit") {
      this._submitButton.click();
    }
  }
  _submitIfValid(e3) {
    const isValid = this.validate();
    if (!isValid) {
      e3.preventDefault();
    } else {
      this._submitButton.setAttribute("disabled", "disabled");
      this._clearStoredData();
      this.dispatchEvent(new CustomEvent("jb-form:submit", { detail: e3 }));
    }
  }
  render() {
    const sectionHeadline = this.sectionHeadline ? x`<jb-section-headline .headline=${this.sectionHeadline.headline}></jb-section-headline>` : T;
    const formHeader = this.generalConcern || this.recipient ? x`<div class="formheader">
              <p class="general-concern">${this.generalConcern}</p>
              ${this.recipient ? x`<h5>${this.recipient}</h5>` : T}
              ${this.recipientPosition ? x`<p>${this.recipientPosition}</p>` : T}
              ${this.recipientPhone ? this._renderPhone(this.recipientPhone) : T}
            </div>
            ${this.formHeader ? x`<h3>${this.formHeader}</h3>` : T}` : T;
    return x`${sectionHeadline} ${formHeader}
      <form
        @formdata=${this._notifyCustomElements}
        @submit=${this._submitIfValid}
        action=${this.action}
        class="${e2({ bordered: !!this.bordered })}"
        enctype="multipart/form-data"
        method="post"
      >
        ${this._renderHiddenFields()}
        <slot
          class="${e2({ grid: !!this.displayGrid, flex: !this.displayGrid })}"
          @jb-button:clicked=${this._submit}
        ></slot>
        ${this.validationFieldname ? this._renderValidationFields() : T}
        <input
          type="hidden"
          class="internal"
          name="form_analytics_time"
          id="form_analytics_time"
        />
        <button
          type="submit"
          class="hidden"
        ></button>
      </form>`;
  }
};
JbForm.styles = r(jb_form_default);
__decorateClass([
  n()
], JbForm.prototype, "action", 2);
__decorateClass([
  n({ type: Object })
], JbForm.prototype, "additionalData", 2);
__decorateClass([
  n({ type: Boolean })
], JbForm.prototype, "bordered", 2);
__decorateClass([
  n({ type: Array })
], JbForm.prototype, "contentElements", 2);
__decorateClass([
  n({ type: Boolean })
], JbForm.prototype, "displayGrid", 2);
__decorateClass([
  n()
], JbForm.prototype, "formHeader", 2);
__decorateClass([
  n()
], JbForm.prototype, "formIdentifier", 2);
__decorateClass([
  n()
], JbForm.prototype, "generalConcern", 2);
__decorateClass([
  n()
], JbForm.prototype, "multiformSubline", 2);
__decorateClass([
  n()
], JbForm.prototype, "recipient", 2);
__decorateClass([
  n()
], JbForm.prototype, "recipientPhone", 2);
__decorateClass([
  n()
], JbForm.prototype, "recipientPosition", 2);
__decorateClass([
  n({ type: Object })
], JbForm.prototype, "sectionHeadline", 2);
__decorateClass([
  n()
], JbForm.prototype, "trackingSubject", 2);
__decorateClass([
  n()
], JbForm.prototype, "validationFieldname", 2);
__decorateClass([
  e('input[name="tx_sitepackage[referrerOriginalUrl]"]')
], JbForm.prototype, "_referrerOriginalUrlField", 2);
__decorateClass([
  e('input[name="tx_sitepackage[referrerTitle]"]')
], JbForm.prototype, "_referrerTitleField", 2);
__decorateClass([
  e('input[name="tx_sitepackage[referrerUrl]"]')
], JbForm.prototype, "_referrerUrlField", 2);
__decorateClass([
  e('button[type="submit"].hidden')
], JbForm.prototype, "_submitButton", 2);
JbForm = __decorateClass([
  customElement("jb-form")
], JbForm);

export {
  JbForm
};
